<template>
<!-- 图片集 -->
    <div class="gallery_box">
        <div class="photo-gallery" id="scroll">
            <div style="min-height: 95%;">
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
                <van-empty :image="require('@/assets/null.svg')" v-else-if="imgList.length == 0"/>
                <img v-else v-lazy="item.image_url" alt="" v-for="(item,index) in imgList" :key="index" @click="imagePreview(item,index)">
            </div>
            <Support></Support>
        </div>
        <sideBar></sideBar>
        <van-image-preview v-model="imgShow" :closeable="true" :showIndicators="false" :startPosition="imgIndex" :images="images">
             <template v-slot:cover>
                 <div class="arrow">
                     <div class="left" @click="arrowLeft(imgIndex)">
                         <van-icon name="arrow-left" />
                     </div>
                     <div class="left" @click="arrowRight(imgIndex)">
                         <van-icon name="arrow" />
                     </div>
                 </div> 
             </template>
        </van-image-preview>
        <!-- <div class="suspend-btn">
            <div class="back-top" @click="backTop" v-if="flag_scroll">
                <img src="@/assets/TuPian/backtop.svg" alt="">
            </div>
            <div class="slide-box" @click="carouselPreview" v-show="imgList.length != 0">
                <img src="@/assets/TuPian/slide.svg" alt="">
                <p>轮播</p>
            </div>
            <div class="home-box" @click="gohome">
                <img src="@/assets/icon/home.svg" alt="">
                <p>首页</p>
            </div>
        </div> -->
    </div>

</template>

<script>
import { ImagePreview } from "vant"; // 引入Vant图片预览组件
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import { mapState } from 'vuex'
export default {
    components:{
        sideBar,
        Support
    },
    data(){
        return{
            params:{},
            imgList:[],
            flag_scroll:false,
            Loading:false,
            site_id:'',    //云展厅传入站点id
            show_grouping:'', // 云展厅传入分组id
            imgShow:false,
            imgIndex:'0',
            images:[],
        }
    },
    created(){
        this.params = this.$route.query;
        if(this.params.module_id){
            this.get_image_list();
        }else{
            if(this.$route.params.site_id){
                sessionStorage.setItem('site_id', JSON.stringify(this.$route.params.site_id))
                sessionStorage.setItem('show_grouping', JSON.stringify(this.$route.params.show_grouping))
                this.site_id = this.$route.params.site_id
                this.show_grouping = this.$route.params.show_grouping
                this.showroom_picture_list()
            }else{
                this.site_id = JSON.parse(sessionStorage.getItem('site_id'))
                this.show_grouping = JSON.parse(sessionStorage.getItem('show_grouping'))
                this.showroom_picture_list()
            }
        }
    },
    mounted(){
        let width = document.body.clientWidth;
        this.bannerHeight = (width / 16 * 9)/100;
        window.addEventListener('scroll', this.handleScroll, true)
        // setInterval(()=>{
        //     document.documentElement.scrollTop += 1;
        // },30)
    },
    computed: {
        ...mapState(['themecolors'])
    },
    methods:{
        gohome(){
            this.$router.push(`/event/${window.sessionStorage.getItem("siteUrl")}`)
        },
        // 展厅图片分类列表
        showroom_picture_list(){
            this.Loading = true;
            this.images = []
            this.$store.dispatch("showroom_picture_list",{
                site_id:this.site_id,
                show_grouping:this.show_grouping
                // show_grouping:218
            }).then(res=>{
                if(res.data.code === 200){
                    this.Loading = false;
                    this.imgList = res.data.data.module_content
                    this.imgList.forEach(item=>{
                        this.images.push(item.image_url)
                    })
                    document.title = res.data.data.module_title ? res.data.data.module_title : (sessionStorage.getItem('wei-title') || '轻微站');
                }
            })
        },
        get_image_list(){
            this.Loading = true;
            this.images = []
            this.$store.dispatch("get_image_list",{
                site_id:this.params.site_id,
                module_id:this.params.module_id,
            }).then(res=>{
                this.Loading = false;
                if(res.data.code == 200){
                    document.title = res.data.data.module_title ? res.data.data.module_title : (sessionStorage.getItem('wei-title') || '轻微站');
                    this.imgList = res.data.data.module_content;
                    this.imgList.forEach(item=>{
                        this.images.push(item.image_url)
                    })
                }
            })
        },
        handleScroll(env){
            let scrollTop = document.getElementById('scroll').scrollTop
            if(scrollTop > 100){
                this.flag_scroll = true
            }else {
                this.flag_scroll = false
            }
        },
        imagePreview(items,index){
            this.imgShow = true
            this.images.forEach((item,index)=>{
                if(item == items.image_url){
                    this.imgIndex = index
                }
            })
        },
        backTop(){
            document.getElementById('scroll').scrollTop = 0;
            this.flag_scroll = false;
        },
        carouselPreview(){
            let img = [];
            this.imgList.forEach(i=> img.push(i.file_link_url))
            ImagePreview({
                images:img, //需要预览的图片 URL 数组
                showIndex:true, //是否显示页码
                loop:false, //是否开启循环播放
                closeable: true,  // 显示关闭按钮
                startPosition:0, //图片预览起始位置索引
            });
        },
        arrowLeft(index){
            let idx = index - 1
            if(idx == -1){
                idx = this.images.length -1
            }
            this.imgIndex = idx
            
        },
        arrowRight(index){
            let idx = index + 1
            if(idx == this.images.length){
                idx = 0
            }
            this.imgIndex = idx
        },
    }
}
</script>

<style lang="less" scoped>
/deep/.van-empty,/deep/.van-loading{
    position: absolute;
    top:30%;
    left:50%;
    transform:translateX(-50%);
}
/deep/.van-empty{
    width: 100%;
    .van-empty__image{
        width: 300px;
        height: 300px;
    }
}
/deep/.van-loading {
    top: 45%;
}
.gallery_box{
    box-sizing: border-box;
}
.photo-gallery{
    height: 100%;
    overflow-y: scroll;
    img{
        display: block;
        width: 100%;
        height: auto;
    }
}
/deep/.van-image-preview__cover{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    .arrow{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;
        .left{
            font-size: 15px;
            background: #c8c9cc;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 4px;
            cursor: pointer;
        }
    }
}
// .suspend-btn{
//     position: fixed;
//     bottom: 15%;
//     right: 3%;
//     div{
//         width: .42rem;
//         height: .42rem;
//         background: rgba(0, 204, 153, .8);
//         border-radius: 50%;
//     }
//     .back-top{
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         img{
//             width: .21rem;
//         }
//     }
//     .slide-box{
//         margin-top: .1rem;
//         padding-top: .08rem;
//         line-height: .12rem;
//         font-size: .12rem;
//         color: #fff;
//         text-align: center;
//         img{
//             display: inline-block;
//             width: .16rem;
//             margin: 0;
//         }
//     }
//     .home-box{
//         margin-top: .1rem;
//         padding-top: .06rem;
//         line-height: .12rem;
//         font-size: .12rem;
//         color: #fff;
//         text-align: center;
//         img{
//             width: .16rem;
//             margin: 0 auto;
//         }
//     }
// }

</style>